@import "vars";

*,
html,
body {
    margin: 0px;
    padding: 0px;
    color: $text-color-primary
}

#root {
    position: relative;
}

.hidden {
    display: none;
}

.logo {
    width: 150px;
}

.d-flex {
    display: flex;
}

.text-left {
    text-align: left !important;
}

a {
    text-decoration: none;
    color: $text-color-primary;
}

a:hover {
    color: $text-color-secondary;
}

.logo-big {
    width: 250px;
}

.grey-btn {
    color: $text-color-secondary  !important;
    border: 2px solid $text-color-secondary;
    height: max-content;
    font-weight: 600;
}

.hamburger {
    width: 50px;
}

.navbar-toggler-icon {
    width: 100%;
    height: max-content;
}

body {
    background-image: url('../img/cray-bg.jpg');
    min-height: calc(100vh - 72px);
}

.bottom {
    bottom: 0px;
}

.right {
    right: 50px;
}

.input-width {
    width: 370px;
}

.own-input,
.labels {
    width: 100%;
}

.own-input {
    color: $text-color-secondary;
    border: 0px;
    border-bottom: 2px solid $text-color-secondary;
    background: transparent;
    min-width: 300px !important;
}

.writings {
    margin-top: -5px;
    margin-left: 10px;
}

.fixed-prompts {
    width: 700px;
}

.success {
    color: #000;
    border-radius: 5px;
    text-align: center;
    white-space: pre;
}

.error {
    color: #000;
    border-radius: 5px;
    text-align: center;
    white-space: pre;
}

.small-padding {
    padding: 10px;
}

.small-top-margin {
    margin-top: 10px;
}

.bottom-line {
    border-bottom: 2px solid $text-color-secondary;
    margin: 20px
}

#messages {
    margin-bottom: 0px;
}

.white-color {
    color: #fff;
}

.chat-title {
    padding: 10px;
    text-align: center;
    background-color: $text-color-primary;
    margin: 0px !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.chat {
    width: 500px;
}

.chat-tilt {
    position: absolute;
    background: #ff0000;
    height: 20px;
    width: 20px;
    top: 52px;
    right: 10px;
    border-radius: 50px;
}

.clickable {
    cursor: pointer;
}

.margin-top-slight-fix {
    margin-top: -5px;
}

.message-input {
    height: 40px;
}

.chat {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .msg-input {
        height: 40px;
        background-color: #fff;
    }

    .players {
        overflow-x: scroll;
        height: 90px;
        background-color: #fff;
    }

    .bottom-line {
        background-color: #fff;
        margin: 0px 0px 20px;
        padding: 20px 0px;
    }

    .messages {
        background-color: #fff;
        height: 350px;
        overflow-y: scroll;
        padding: 0px 20px
    }

    .your-message,
    .player-message {
        max-width: 300px;
        width: max-content;
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 10px;
        background-color: #000;
        color: #fff;
    }

    .username {
        color: #fff;
    }

    .date {
        color: $text-color-secondary;
        font-size: 13px;
    }

    .message {
        color: #fff;
    }

    .your-message {
        margin-right: auto;
    }

    .player-message {
        margin-left: auto;
    }
}

.chat-hidden {
    background: #fff;
}

.max-content {
    width: max-content;
}

.player {
    padding: 10px;
    margin-right: 10px;
    margin-left: 10px;
    white-space: nowrap;
    align-self: center;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $text-color-secondary;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $text-color-secondary;
}

.top--margin-fix {
    margin-top: -10px;
}

textarea,
.textarea {
    resize: none;
    border: none;
    border-bottom: 2px solid $text-color-secondary;
    height: 70px;
    width: 100%;
    background-color: #fff;
    padding: 5px !important;
    font-size: 14px;
}

.dot {
    width: 10px;
    height: 10px;
    margin: -2px 2px 5px;
}

.dots-wrapper {
    width: 100%;
    padding: 0px 2px;
    display: flex;
    flex-wrap: wrap;
}

.keywords {
    width: 166px;
}

.title,
.fields {
    border: 1px solid #000;
    height: 100px;
    background-color: $text-color-secondary
}

.answer-row,
.title {
    width: 166px;
}

.roll:hover {
    content: url("../img/reset-button-active.png");
}

.choosen {
    background-color: #0f6 !important;
}

.to-chat {
    width: 24px;
    height: 24px;
    left: 20px;
    top: 12px;
}

.pass {
    cursor: auto !important;
}

.max-width-400 {
    max-width: 400px;
}

.w-spacer {
    width: 80px;
}

.z-index-on-top {
    z-index: 1000;
}

.background-shadowy {
    background: rgba(0, 0, 0, 0.3);
}

.background-to-top {
    top: 0px;
}

.pop-up {
    margin-top: -147px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 400px;
    max-height: 300px;
    height: 100%;
    background: #fff;
    text-align: center;
}

.text-right {
    text-align: right !important;
}

.chat {

    .player,
    .username,
    .date {
        font-size: 12px;
    }

}

@media(max-width: 1500px) {

    .keywords,
    .answer-row,
    .title {
        width: 130px;
    }
}

@media(max-width: 1360px) {

    html,
    body {
        overflow-x: scroll;
    }
}

@media(max-width: 860px) {
    .input-width {
        width: 100%;
    }

    .login,
    .register {
        margin: 0px 20px;
    }
}

@media(max-width: 767px) {
    .fix-width-mobile {
        width: 100% !important;
    }

    .input-width {
        width: 400px;
    }

    .login,
    .register {
        margin: 0px 0px;
    }
}

@media(max-width: 570px) {
    .game .chat {
        width: 100%;
        right: 0px;
    }
}

@media(max-width: 450px) {
    .input-width {
        width: 100%;
    }

    .chat .your-message,
    .chat .player-message {
        max-width: 240px;
    }
}

@media(max-height: 600px) {
    .chat .bottom-line {
        margin: 0px 0px 10px;
        padding: 10px 0px;
    }

    .chat .players {
        overflow-x: scroll;
        height: 70px;
    }

    .chat .messages {
        height: 150px;
    }
}

.text-start-dd{
    text-align: start;
    margin-top: 10px;
}

.center-sign-in{
    margin-left: auto;
    margin-right: auto;
}